import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { resetTg } from "@/api/userInfo"; // 导入重置 Telegram 的接口

export default {
  name: "newTutorialDetails",

  setup() {
    const route = useRoute();
    const router = useRouter();
    const showEmailDialog = ref(false);
    const showTelegramDialog = ref(false);
    const email = ref("");
    const telegram = ref(""); // 从本地存储中获取并解析 userInfo

    const userInfoString = localStorage.getItem("userInfo");
    const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

    const onClickLeft = () => history.back();

    const userInfo2 = reactive({
      userName: userInfo.userName,
      phone: userInfo.loginName
    });

    const cancelDialog = type => {
      if (type === "email") {
        email.value = ""; // 清空 E-mail 输入框

        showEmailDialog.value = false;
      } else if (type === "telegram") {
        telegram.value = ""; // 清空 Telegram 输入框

        showTelegramDialog.value = false;
      }
    };

    const confirmEmailDialog = () => {
      console.log("Confirmed email:", email.value);
      email.value = ""; // 清空输入框的值

      showEmailDialog.value = false;
    };

    const confirmTelegramDialog = async () => {
      try {
        // 假设你从本地获取到的 loginName
        const loginName = userInfo.loginName; // 这里要替换为实际登录名

        const res = await resetTg({
          loginName,
          telegram: telegram.value
        });

        if (res.code === 1) {
          _Toast.success("Telegram updated successfully");
        } else {
          _Toast.fail(res.msg || "Failed to update Telegram");
        }
      } catch (error) {
        _Toast.fail("Error occurred while updating Telegram");
      } finally {
        telegram.value = ""; // 清空输入框的值

        showTelegramDialog.value = false;
      }
    };

    return {
      onClickLeft,
      showEmailDialog,
      showTelegramDialog,
      userInfo2,
      email,
      telegram,
      cancelDialog,
      confirmEmailDialog,
      confirmTelegramDialog
    };
  }

};